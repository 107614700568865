<template>
  <v-card elevation="1" :class="$vuetify.breakpoint.smAndDown ? 'px-3 py-3' : 'px-10 py-3'" tile flat
          class="grey lighten-4 non-printable"
          style="display: flex; flex-direction: column;" :style="$vuetify.breakpoint.smAndDown
          ? 'height: 100%'
          : personal ? 'height: calc(100vh - 74px)' : 'height: 100vh'">
    <v-dialog v-model="show_dialog" persistent max-width="1200">
      <v-card elevation="3" class="" height="86vh">
        <div class="d-flex" style="height: 28px; flex: 0">
          <v-btn depressed small @click="closeDialog">
            <v-icon small left>mdi-arrow-left</v-icon>
            Повернутись назад
          </v-btn>
          <v-spacer></v-spacer>
        </div>
        <v-card-text class="grey lighten-5 px-4 pt-3 pb-3" style="height: 70px;">
          <v-text-field v-model="comment_dialog" hide-details
                        placeholder="Ваш коментар...."
                        filled color="grey darken-2"
                        rounded
                        @keypress.enter="addDialogComment"
          />
        </v-card-text>
        <v-card-text class="grey lighten-5 px-4 py-3" style="height: calc(100% - 100px); overflow-y: auto" id="chat">
          <template v-for="item in comments">
            <div :key="`comment-${item.id}`" :class="item.self ? '' : 'right-side'">
              <div class="comment-wrapper">
                <div class="comment-item mb-2">
                  <span class="comment-label">
                    {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
                  </span>
                  <span v-if="!item.self">
                    {{ item.position ? `, ${item.position}` : `, ${item.address}` }}
                  </span>
                  <div class="comment-content">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_result" fullscreen>
      <div class="wr print-page-component" style="background: #ffffff; height: 100%" v-if="vote_result.rows">
        <v-toolbar color="grey lighten-4" elevation="1" class="non-printable" height="54">
          <v-btn depressed @click="closeResultDialog" color="grey lighten-3">
            <v-icon color="grey darken-2" left>mdi-close</v-icon>
            Закрити
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed @click="printPage" color="grey lighten-3">
            <v-icon color="grey darken-2">mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="wrapper-page">
          <div class="page page-a4 printable" id="print" ref="document">
            <div class="form-table-custom" style="min-height: 120px">
              <table style="width: 100%;">
                <caption class="table-caption">
                  <div style="margin-bottom: 6px; font-weight: 500; display: flex">
                    <div>Результат голосування від {{vote_result.create_date | formatDate}}</div>
                  </div>
                  <div style="text-align: left; font-size: .90rem; margin-bottom: 4px;">Тема: {{ vote_result.theme }}</div>

                  <div class="mt-3 mb-4">
                    <div class="res-stat">
                      <div class="res-stat-title font-weight-medium">Питання</div>
                      <div class="res-stat-count font-weight-medium">К-сть</div>
                      <div class="res-stat-count font-weight-medium">К-сть, %</div>
                      <div class="res-stat-count font-weight-medium">Площа</div>
                      <div class="res-stat-count font-weight-medium">Площа, %</div>
                    </div>
                    <div
                        v-for="(item, idx) in vote_result.stats" :key="`stat-res-${idx}`"
                        class="res-stat"
                    >
                      <div class="res-stat-title">{{ item.title }}</div>
                      <div class="res-stat-count">{{ item.count }}</div>
                      <div class="res-stat-count">{{ item.count_percent | formatNumber('0.0000') }}</div>
                      <div class="res-stat-count">{{ item.square }}</div>
                      <div class="res-stat-count">{{ item.square_percent | formatNumber('0.0000') }}</div>
                    </div>
                  </div>
                </caption>
                <thead class="table-head">
                <tr>
                  <th style="width: 8%">№ п/п</th>
                  <th>Адреса</th>
                  <th style="width: 18%">Проголосував</th>
                  <th style="width: 12%">Площа</th>
                  <th style="width: 18%">Відповідь</th>
                </tr>
                </thead>
                <tbody class="table-body">
                <tr v-for="(item, idx) in vote_result.rows" :key="`res-${idx}`" class="table-row">
                  <td style="width: 8%; text-align: center; border-right: 1px solid rgba(203, 203, 203, 0.5);">
                    {{ idx + 1 }}
                  </td>
                  <td style="border-right: 1px solid rgba(203, 203, 203, 0.5);">
                    {{ item.address }}
                  </td>
                  <td style="width: 18%; border-right: 1px solid rgba(203, 203, 203, 0.5);">
                    {{ item.voter_name }}
                  </td>
                  <td style="width: 12%; border-right: 1px solid rgba(203, 203, 203, 0.5);">
                    {{ item.square | formatNumber }}
                  </td>
                  <td style="width: 18%; border-right: 1px solid rgba(203, 203, 203, 0.5);">
                    {{ item.answer }}
                  </td>
                </tr>
                <tr v-if="!vote_result.rows.length">
                  <td colspan="5"
                      class="success--text"
                      style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
                  >
                    Таблиця порожня. Спершу додайте рядки)
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog fullscreen v-model="show_images">
      <v-card style="height: 100%; position:relative;" class="grey lighten-3">
        <v-btn @click="closeShowImages" style="position:absolute; right: 5px; top: 4px; z-index: 99;">Закрити</v-btn>
        <v-card tile flat height="100%">
          <v-window
              next-icon="mdi-chevron-right"
              prev-icon-icon="mdi-chevron-left"
              style="height: 100%"
              show-arrows
          >
            <template v-slot:prev="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="pa-4" size="44" color="white">mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="pa-4" size="44" color="white">mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <v-window-item
                v-for="(file,i) in preview_files"
                :key="`file-preview-${i}`"
                style="height: 100%"
            >
                <v-img :src="`${file}`"
                       height="100%"
                       eager
                       aspect-ratio="1"
                       class="grey lighten-2"
                       contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
            </v-window-item>
          </v-window>
        </v-card>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="show_drawer" app right temporary :width="$vuetify.breakpoint.xs ? '90%' : 400">
      <v-card height="100%" class="px-5 py-3">
        <v-row>
          <v-col cols="12" class="pb-1">
            <v-card elevation="0" color="grey lighten-4" class="text-center pa-2">
              Налаштування
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field v-model="filters.TEXT" hide-details append-icon="mdi-magnify"
                          placeholder="Введіть текст для пошуку" color="grey darken-1"
                          :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" class="pb-0 pt-0">
            <v-checkbox v-model="filters.INFORMING" hide-details label="Інформування" color="success"/>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-checkbox v-model="filters.POLL" hide-details label="Опитування" color="success"/>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-checkbox v-model="filters.VOTING" hide-details label="Голосування" color="success"/>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <p class="mb-0 mt-1 grey--text text--darken-2 radio-header">Статус завершення</p>
            <v-radio-group v-model="filters.CLOSED" row hide-details class="mt-1">
              <v-radio color="success" label="Усі" :value="null" class="custom-radio"/>
              <v-radio color="success" label="Закриті" :value="true" class="custom-radio"/>
              <v-radio color="success" label="Відкриті" :value="false" class="custom-radio"/>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <p class="mb-0 mt-1 grey--text text--darken-2 radio-header">Статус прочитаності</p>
            <v-radio-group v-model="filters.READ" row hide-details class="mt-1">
              <v-radio color="success" label="Усі" :value="null" class="custom-radio"/>
              <v-radio color="success" label="Прочитані" :value="true" class="custom-radio"/>
              <v-radio color="success" label="Не прочитані" :value="false" class="custom-radio"/>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
    </v-navigation-drawer>
    <v-card elevation="3" class="px-3 py-3 d-flex align-center"
            style="font-size: 1.1rem; height: 56px">
      Опитування/Голосування/Інформування
      <v-spacer></v-spacer>
      <v-btn icon class="mr-2 grey lighten-3" v-if="$vuetify.breakpoint.smAndDown" @click="show_drawer = true">
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
      <MainModal
          :main="{ component: 'Vote', title: 'Повідомлення' }"
          :button="{ icon: true, color: 'grey lighten-3 mr-2', buttonIcon: 'mdi-plus' }"
          v-if="!readOnly && !disabled"
          :nonClickable="nonClickable"
          :item="edit_item"
          :modal="show_edit_modal"
          @updateItemModal="afterEditModalClose"
      />
    </v-card>
    <v-card :style="$vuetify.breakpoint.smAndDown ? '' : 'height: calc(100% - 74px);'" tile flat class="mt-4 grey lighten-4">
      <v-row style="height: 100%">
        <v-col cols="12" sm="12" md="8" xl="9" style="height: 100%">
          <v-card :height="$vuetify.breakpoint.smAndDown ? '' : '100%'" style="overflow-y: auto"
                  tile flat :class="$vuetify.breakpoint.smAndDown ? 'grey lighten-4' : 'grey lighten-3 py-2'"
                  rounded
                  id="scrollArea"
          >
            <div v-for="(item, index) in items" :key="index" class="pt-0 mb-4" :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-6'">
              <v-card max-width="800" class="mx-auto" elevation="2"
                      v-intersect="{
                        handler: onIntersect,
                        options: {
                          threshold: [0.5, 1.0],
                        }
                      }"
                      :data-vote-id="item.id"
                      :data-vote-type="item.vote_type"
                      :data-vote-read="item.read ? 1 : 0"
              >
                <v-card-text class="d-flex pb-1 pt-2">
                  <v-avatar size="50" class="my-1 mr-3" color="grey lighten-4" style="flex: 0 0 50px">
                    <v-icon :color="getVotingType(item.vote_type, 'color')" size="34">
                      {{ voting_closed(item.close_date) ? 'mdi-lock-outline' : 'mdi-vote-outline' }}
                    </v-icon>
                  </v-avatar>
                  <div class="mt-1" style="flex: 1; font-size: .8rem">
                    <div class="d-flex">
                      <div>
                        {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
                      </div>
                      <v-spacer></v-spacer>
                      <div style="font-weight: bold; font-size: .78rem">
                        {{ item.position_name ? item.position_name : item.owner_name || item.owner_name_ }}
                      </div>
                    </div>
                    <div style="font-size: .94rem; font-weight: 500; display: flex; align-items: center">
                      <div style="flex: 1;" class="mt-2">
                        {{ item.theme }}
                      </div>
                      <div style="flex: 0 0 40px; text-align: right;" v-if="(item.owner === profile.id || admin)">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                          </template>

                          <v-list dense nav>
                            <v-list-item @click.stop="onItemEdit(item)" v-if="item.owner === profile.id">
                              <v-list-item-title>Редагувати</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="getVoteResult(item.id)"
                                v-if="(item.owner === profile.id || admin)
                                      && voting_closed(item.close_date)
                                      && item.vote_type !== 'INFORMING'">
                              <v-list-item-title>Результат</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    <div class="mt-2 mb-1 d-flex">
                      <v-chip small>
                        {{ getVotingType(item.vote_type, 'text') }}
                      </v-chip>
                      <v-chip small v-if="voting_closed(item.close_date) && item.vote_type !== 'INFORMING'" class="ml-2"
                              color="error lighten-1">
                        Завершено {{ item.close_date | formatDate }}
                      </v-chip>
                      <v-chip small v-if="!voting_closed(item.close_date) && item.vote_type !== 'INFORMING'"
                              class="ml-2">
                        Відкрите до {{ item.close_date | formatDate }}
                      </v-chip>
                      <v-chip small color="success" v-if="item.vote_type === 'INFORMING' && item.read" class="ml-2">
                        Прочитано
                      </v-chip>
                    </div>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pb-0 pt-1" style="font-size: .94rem; font-weight: 500">
                  {{ item.theme }}
                </v-card-text>
                <v-card-text class="pt-0 pb-2" style="font-size: .84rem; color: #7c7c7c">
                  {{ item.show_full ? item.text : item.short_text }}
                  <span v-if="!item.show_full" class="show-full-text" @click="onShowFullClick(item.id)">
                    Показати більше
                  </span>
                </v-card-text>
                <v-card-text class="pt-1">
                  <v-row>
                    <v-col
                        v-for="file in item.files"
                        :key="`file-${item.id}-${file.id}`"
                        class="d-flex child-flex"
                        :cols="item.files.length === 1 ? 12 : 6"

                    >
                      <v-img
                          :src="`${BASE_URL_AXIOS}/${file.file_path}`"
                          :lazy-src="`${BASE_URL_AXIOS}/${file.file_path}`"
                          aspect-ratio="1"
                          max-height="320"
                          eager
                          class="grey lighten-2"
                          style="cursor: pointer"
                          @click="onImageClick(item.files)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider v-if="(item.options || []).length"></v-divider>
                <v-card-text class="pt-2 px-4" v-if="(item.options || []).length">
                  <div
                      class="answer-line"
                      style="padding: 5px 0;"
                      v-for="(question, idx) in item.options" :key="idx"
                  >
                    <div class="answer-stat" style="display: flex" v-if="item.vote_type === 'POLL'">
                      <div class="question" style="flex: 1">
                        {{ question.title }}
                      </div>
                      <div class="votes" style="flex: 0 0 80px; text-align: right">
                        {{ question.votes }} голосів
                      </div>
                    </div>
                    <div class="answer-progress" style="display: flex; flex-wrap: nowrap;">
                      <v-progress-linear
                          :value="getPercent(question)"
                          :color="getQuestionColor(question.positive, item.vote_type)"
                          striped
                          height="20"
                      >
                        <template v-slot:default>
                          <span
                              style="position: absolute; left: 10px; font-weight: 500"
                              v-if="item.vote_type === 'VOTING'"
                          >{{ question.title }}
                          </span>
                          <strong v-if="item.vote_type === 'VOTING'">{{ getPercent(question) }}% ({{ question.votes }}
                            голосів)</strong>
                          <strong v-else>{{ getPercent(question) }}%</strong>
                        </template>
                      </v-progress-linear>
                      <v-btn depressed x-small tile
                             :color="item.closed ? 'error' : 'grey darken-1'"
                             :dark="!item.closed"
                             style="flex: 0 0 40px"
                             :disabled="item.closed"
                             @click="doVote(question.id, item.close_date, item.id)"
                             v-if="!item.voted"
                      >
                        <v-icon size="19">mdi-hand-pointing-left</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="answer-line" style="padding: 6px 0;">
                    <div class="answer-stat" style="display: flex" v-if="item.vote_type === 'POLL'">
                      <div class="question" style="flex: 1">
                        Не проголосувало
                      </div>
                    </div>
                    <div class="answer-progress">
                      <v-progress-linear
                          :value="getNotVote('percent', item.options)"
                          color="grey lighten-2"
                          striped
                          height="20"
                      >
                        <template v-slot:default>
                          <strong>{{ getNotVote('percent', item.options) }}% ({{ getNotVote('votes', item.options) }}
                            голосів)</strong>
                        </template>
                      </v-progress-linear>
                    </div>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pt-3 pb-2" v-if="item.last_comment_text">
                  <p class="mb-1 pb-0">Мій останій коментар:</p>
                  <div>
                    <div class="comment-wrapper" style="min-width: 200px">
                      <div class="comment-item">
                        <div class="comment-label">{{
                            item.last_comment_date | formatDate('DD.MM.YYYY HH:mm:ss')
                          }}
                        </div>
                        <div class="comment-content">{{ item.last_comment_text }}</div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text class="pt-3">
                  <v-text-field hide-details
                                placeholder="Ваш коментар...."
                                filled color="grey darken-2"
                                rounded
                                :id="`vote_item_comment_${item.id}`"
                                :ref="`vote_item_comment_${item.id}`"
                                @keypress.enter="addComment(item.id)"
                                :disabled="disabled"
                  />
                </v-card-text>
                <v-card-text class="pt-1">
                  <div class="bottom-items">
                    <div class="bottom-item">
                      <v-icon size="19" color="success" class="mr-1">mdi-comment-text-multiple-outline</v-icon>
                      {{ `${$vuetify.breakpoint.mdAndUp ? 'Коментарів:' : ''} ${item.message_count || 0}` }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn depressed plain
                           @click="showCommentDialog(item.id)"
                           :small="$vuetify.breakpoint.smAndDown"
                    >
                      Показати коментарі
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div v-if="!items.length" class="pt-0 mb-4" :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-6'">
              <v-card max-width="800" class="mx-auto" elevation="2">
                <v-card-text class="pb-1 pt-4 text-center">
                  <v-icon size="120" color="success">mdi-emoticon-sad-outline</v-icon>
                </v-card-text>
                <v-card-text class="pb-4 pt-2 text-center" style="font-size: 1.1rem;">
                  Нажаль, на даний момент нові події відсутні.
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="3" v-if="$vuetify.breakpoint.mdAndUp">
          <v-card height="100%" class="px-5 py-3">
            <v-row>
              <v-col cols="12" class="pb-1">
                <v-card elevation="0" color="grey lighten-4" class="text-center pa-2">
                  Налаштування
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field v-model="filters.TEXT" hide-details append-icon="mdi-magnify"
                              placeholder="Введіть текст для пошуку" color="grey darken-1"
                />
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-checkbox v-model="filters.INFORMING" hide-details label="Інформування" color="success"/>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-checkbox v-model="filters.POLL" hide-details label="Опитування" color="success"/>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-checkbox v-model="filters.VOTING" hide-details label="Голосування" color="success"/>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <p class="mb-0 mt-1 grey--text text--darken-2 radio-header">Статус завершення</p>
                <v-radio-group v-model="filters.CLOSED" row hide-details class="mt-1">
                  <v-radio color="success" label="Усі" :value="null" class="custom-radio"/>
                  <v-radio color="success" label="Закриті" :value="true" class="custom-radio"/>
                  <v-radio color="success" label="Відкриті" :value="false" class="custom-radio"/>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <p class="mb-0 mt-1 grey--text text--darken-2 radio-header">Статус прочитаності</p>
                <v-radio-group v-model="filters.READ" row hide-details class="mt-1">
                  <v-radio color="success" label="Усі" :value="null" class="custom-radio"/>
                  <v-radio color="success" label="Прочитані" :value="true" class="custom-radio"/>
                  <v-radio color="success" label="Не прочитані" :value="false" class="custom-radio"/>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  VOTE_ADD,
  VOTE_COMMENT_CREATE,
  VOTE_COMMENT_GET_ALL,
  VOTE_COMMENT_UPDATE_LAST,
  VOTE_ITEM_CREATE,
  VOTE_ITEM_GET_ALL,
  VOTE_ITEM_UPDATE,
  VOTE_UPDATE_OPTION,
  VOTE_UPDATE_USER_VOTED,
  VOTE_ITEM_SHOW_FULL_TEXT,
  VOTE_ITEM_READ,
  VOTE_SET_LAST_DATE, VOTE_GET_STATS
} from "@/store/actions/vote";
import {getVotingType, getDateFromString, addDaysToStringDate} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import {BASE_URL_AXIOS} from "@/utils/axios";
import voteAPI from "@/utils/axios/vote"

export default {
  name: "VotingNew",
  props: {
    personal: {
      type: Boolean,
      default: false,
    },
    person_hash: {
      type: String,
      default: ''
    },
      disabled: {
          type: Boolean,
          default: false
      }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters(
        {
          items: 'getVotes',
          comments: 'getComments',
          socket_data: 'get_socket',
          profile: 'getProfile',
          organization: 'getCurrentOrganization',
          admin: 'isAdmin'
        }
    ),
    randomNumber() {
      const max = 180
      return Math.floor(Math.random() * max);
    },
  },
  data() {
    return {
      show_drawer: false,
      filters: {
        POLL: true,
        VOTING: true,
        INFORMING: true,
        READ: false,
        CLOSED: false,
        BUILDINGS: [],
        TEXT: ''
      },
      images: 2,
      readOnly: false,
      nonClickable: false,
      comment: '',
      comment_dialog: '',
      comment_id: null,
      edit_item: {},
      show_edit_modal: false,
      show_dialog: false,
      show_result: false,
      vote_result: {},
      preview_files: [],
      show_images: false,
      BASE_URL_AXIOS,
    }
  },
  methods: {
    ...mapActions(
        {
          fetchItems: VOTE_ITEM_GET_ALL,
          vote_add: VOTE_ADD
        }
    ),
    fetch_with_filter() {
      const filters = JSON.parse(JSON.stringify(this.filters))
      if (!filters.BUILDINGS.length) {
        filters.BUILDINGS = null
      }

      this.fetchItems(filters)
    },
    getVotingType,
    copyObject(obj) {
      return obj.map(i => {
        return {...i, delete: false}
      })
    },
    onItemEdit(payload) {
      this.edit_item.id = payload.id
      this.edit_item.vote_type = payload.vote_type
      this.edit_item.theme = payload.theme
      this.edit_item.important = payload.important
      this.edit_item.create_date = payload.create_date
      this.edit_item.closed = payload.closed
      this.edit_item.close_date = payload.close_date
      this.edit_item.text = payload.text
      this.edit_item.buildings = payload.buildings
      this.edit_item.options = payload.options
      this.edit_item.files = this.copyObject(payload.files) || []

      this.show_edit_modal = true
    },
    afterEditModalClose() {
      this.edit_item = {}
      this.show_edit_modal = false
    },
    addComment(vote_id) {
      const comment_element = document.getElementById(`vote_item_comment_${vote_id}`)
      let comment = comment_element.value

      if (!comment) return ''

      this.$store.dispatch(VOTE_COMMENT_CREATE, {vote_id: vote_id, text: comment})
          .then(response => {
            if (response) {
              this.$store.commit(VOTE_COMMENT_UPDATE_LAST, response)
              try {
                this.$ws.send_message(Object.assign(response, {ws_type: 'vote_comment'}))
                this.$nextTick(() => {
                  this.$refs[`vote_item_comment_${vote_id}`][0].value = null
                })
              } catch {
                this.$nextTick(() => {
                  this.$refs[`vote_item_comment_${vote_id}`][0].value = null
                })
              }
              // this.autoScroll()
            }
          })
    },
    addDialogComment() {
      if (!this.comment_dialog) return ''
      if (!this.comment_id) return ''

      this.$store.dispatch(VOTE_COMMENT_CREATE, {vote_id: this.comment_id, text: this.comment_dialog})
          .then(response => {
            if (response) {
              this.$store.commit(VOTE_COMMENT_UPDATE_LAST, response)
              try {
                this.$ws.send_message(Object.assign(response, {ws_type: 'vote_comment'}))
                this.comment_dialog = ''
              } catch {
                this.comment_dialog = ''
              }
              this.autoScroll()
            }
          })
    },
    doVote(question_id, closed_date, vote_id) {
      if (this.voting_closed(closed_date)) {
        this.$store.commit(ALERT_SHOW, {text: 'Голосування завершене...', color: 'error lighten-1'})
      }
      this.vote_add(question_id)
          .then(response => {
            if (response) {
              try {
                this.$ws.send_message(Object.assign(response, {ws_type: 'vote_do_vote'}))
              } catch {
                this.$store.commit(ALERT_SHOW, {text: 'Щось не так із WebSocket)'})
              } finally {
                this.$store.commit(VOTE_UPDATE_USER_VOTED, {id: vote_id})
              }
            }
          })
    },
    showCommentDialog(vote_id) {
      this.comment_id = vote_id
      this.$store.dispatch(VOTE_COMMENT_GET_ALL, vote_id)
      this.show_dialog = true
    },
    closeDialog() {
      this.show_dialog = false
      this.comment_id = null
    },
    autoScroll() {
      const chat = document.getElementById('chat')
      if (chat) {
        const scroll = chat.scrollHeight
        const height = chat.clientHeight

        if (scroll >= height) {
          chat.scrollTo(0, 0);
        }
      }
    },
    getQuestionColor(positive, vote_type) {
      if (vote_type === "VOTING") {
        return positive ? 'light-blue lighten-1' : 'error lighten-3'
      } else {
        return 'light-blue lighten-1'
      }
    },
    getPercent(question) {
      return (question.voters ? (question.votes / question.voters) * 100 : 0).toFixed(2)
    },
    getNotVote(type, options) {
      const total_votes = options.reduce((acc, item) => acc + (item.votes || 0), 0)
      const first = options[0]

      if (type === 'votes') {
        return (first.voters ? first.voters - total_votes : 0).toFixed(0)
      }

      return (first.voters ? (first.voters - total_votes) / first.voters * 100 : 0).toFixed(2)
    },
    vote_positive(item) {
      if (item.vote_type === 'VOTING') {
        if (item.options) {
          return (item.options.find(item => item.positive) || {}).votes || 0
        }
        return 0
      }
      return 0
    },
    vote_negative(item) {
      if (item.vote_type === 'VOTING') {
        if (item.options) {
          return (item.options.find(item => !item.positive) || {}).votes || 0
        }
        return 0
      }
      return 0
    },
    voting_closed(close_date) {
      if (!close_date) return false

      const close_js_date = getDateFromString(addDaysToStringDate(close_date, 1))
      const current_date = new Date()

      return close_js_date <= current_date
    },
    onShowFullClick(vote_id) {
      this.$store.commit(VOTE_ITEM_SHOW_FULL_TEXT, {id: vote_id})
    },
    getVoteResult(vote_id) {
      voteAPI.vote_result(vote_id)
        .then(response => response.data)
        .then(data => {
          this.vote_result = data
          this.vote_result.stats.sort((a, b) => a.order - b.order)
          this.show_result = true
        }).catch(err => {
        const error = err.response.data.detail;
        this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
      })
    },
    closeResultDialog() {
      this.vote_result = {}
      this.show_result = false
    },
    onIntersect (entries) {
      const view = (entries[0].intersectionRatio >= 0.5)
      if (view) {
        const target = entries[0].target
        if (target) {
          const vote_id = +target.dataset.voteId
          const vote_type = target.dataset.voteType
          const vote_read = target.dataset.voteRead

          if (vote_type === 'INFORMING' && vote_read === '0') {
            // if (this.items[0].id !== vote_id) {
              this.$store.dispatch(VOTE_ITEM_READ, vote_id)
                .then(() => {
                  this.$store.dispatch(VOTE_GET_STATS)
                })
            // }
          }
        }
      }

    },
    printPage() {
      window.print()
    },
    onImageClick(files) {
      this.preview_files = files.map(item => `${BASE_URL_AXIOS}/${item.file_path}`)
      this.show_images = true
    },
    closeShowImages() {
      this.show_images = false
      this.preview_files = []
    }
  },
  created() {
    this.fetch_with_filter()
    setTimeout(() => {
      this.$store.dispatch(VOTE_SET_LAST_DATE)
    }, 1500)
  },
  watch: {
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload && this.comment_id) {
          if (payload.data.ws_type === 'vote_comment'
              && payload.data.vote_id === this.comment_id
          ) {
            if (!this.comments.find(item => item.id === payload.data.id)) {
              let local_payload = Object.assign({}, payload.data, {self: false})
              this.$store.commit(VOTE_COMMENT_CREATE, local_payload)
              this.autoScroll()
            }
          }

          if (payload.data.ws_type === 'vote_do_vote') {
            this.$store.commit(VOTE_UPDATE_OPTION, payload.data)
          }
        }

        if (payload) {
          if (payload.data.ws_type === 'vote_crud'
              && payload.data.organization_id === this.organization.id
              && payload.owner !== this.profile.id
          ) {
            const obj = this.items.find(item => item.id === payload.data.id)
            if (obj) {
              this.$store.commit(VOTE_ITEM_UPDATE, payload.data)
            } else {
              const vote_type = getVotingType(payload.data.vote_type)
              this.$store.commit(VOTE_ITEM_CREATE, payload.data)
              this.$store.dispatch(VOTE_GET_STATS)
              this.$store.commit(ALERT_SHOW, {text: `Нове ${vote_type}: ${payload.data.theme}`, color: 'success'})
            }
          }
        }
      }
    },
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetch_with_filter()
        }
      }
    },
    filters: {
      deep: true,
      handler() {
        this.fetchItems(this.filters)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.nav-drawer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 100%;
}

.custom-radio {
  :deep(.v-label) {
    font-size: .86rem;
  }
}

.radio-header {
  font-size: .9rem;
  color: #7c7c7c;
}

.bottom-items {
  display: flex;
  align-items: center;

  .bottom-item {
    margin-right: 12px;
    font-size: .9rem;
  }
}

.comment-wrapper {
  display: inline-block;
  max-width: calc(100% - 60px);
}

.comment-item {
  background-color: #efefef;
  border-radius: 22px;
  padding: 5px 16px;

  .comment-label {
    font-size: .74rem;
    line-height: 1.05rem;
    font-weight: bold;
    color: #343434;
    word-break: break-word;
  }

  .comment-content {
    font-size: .92rem;
    line-height: 1.4rem;
    color: #262626;
    word-break: break-word;
  }
}

.right-side {
  display: flex;

  .comment-wrapper {
    text-align: right;
    margin-left: auto;

    .comment-item {
      background-color: #e1e1e1;
    }
  }
}
.show-full-text {
  color: #2f8232;
  font-weight: 500;
  cursor: pointer;
}

.res-stat {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  font-size: .8rem;

  .res-stat-title {
    background-color: #f3f3f3;
    width: 200px;
    text-align: left;
    padding: 3px 6px;
  }
  .res-stat-count {
    background-color: #e7e7e7;
    width: 80px;
    padding: 3px;
    margin-right: 2px;
  }
}

.table-caption {
  width: 100%;
}
.table-head {
  width: 1200px;
  max-width: 1200px;
}
.table-body {
  width: 1200px;
  max-width: 1200px;
}
.table-row {
  width: 1200px;
  max-width: 1200px;
}
.form-table-custom td {
  height: 26px !important;
  font-size: .72rem;
}
.image-carousel {
  height: calc(100% - 72px) !important;
}
</style>